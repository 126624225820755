<div class="container">
  <div class="card mx-auto">
    <div class="card-body">
      <div class="row">
        <div class="row">
          <div class="col-md-11">
            <div class="login-container--header">
              <h2>Create New Custom Pack</h2>
              <p>Please create new custom pack.</p>
            </div>
          </div>
          <div class="col-md-1">
            <div class="close-icon" (click)="modalClose()"><span class="material-icons"> highlight_off </span></div>
          </div>
        </div>
        <form (ngSubmit)="createNewCustomPack()" [formGroup]="createCustomPackForm" novalidate>
          <!-- <div *ngIf="foundErrorLogin" class="alert alert-danger" translate>Username or password incorrect.</div> -->
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Enter a Title</mat-label>
                <input matInput placeholder="Enter Title" formControlName="title" required />
                <mat-error
                  *ngIf="
                    !createCustomPackForm.controls['title'].valid || !createCustomPackForm.controls['title'].untouched
                  "
                >
                  Title is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[0]" width="100%" height="100%" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Enter Sub Title</mat-label>
                <input matInput placeholder="Enter SubTitle" formControlName="subTitle" required />
                <mat-error
                  *ngIf="
                    !createCustomPackForm.controls['subTitle'].valid ||
                    !createCustomPackForm.controls['subTitle'].untouched
                  "
                >
                  Sub Title is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[1]" width="100%" height="100%" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Enter Save Up To Text</mat-label>
                <input matInput placeholder="Enter Save Up To Text" formControlName="saveUptoText" required />
                <mat-error
                  *ngIf="
                    !createCustomPackForm.controls['saveUptoText'].valid ||
                    !createCustomPackForm.controls['saveUptoText'].untouched
                  "
                >
                  Save Up To Text is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[2]" width="100%" height="100%" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Select Plan Name(s)</mat-label>
                <mat-select
                  placeholder="Select Plan Name(s)"
                  multiple
                  required
                  [(ngModel)]="selectedValue"
                  formControlName="planIds"
                  name="planIds"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="searchTxt"
                      placeholderLabel="Search Plan Name(s)"
                      noEntriesFoundLabel="Not found"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let subscriptionDetail of filteredPlansMulti | async"
                    [value]="subscriptionDetail.SubscriptionPlanID"
                    (onSelectionChange)="optionedValue($event, subscriptionDetail)"
                  >
                    {{
                      subscriptionDetail.SubscriptionPlanID +
                        ' (' +
                        subscriptionDetail.SubscriptionPlanName +
                        ' - ' +
                        subscriptionDetail.SubscriptionPlanDurationName +
                        ' | Plan Category - ' +
                        subscriptionDetail.SubscriptionPlanCategory +
                        ')'
                    }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    !createCustomPackForm.controls['planIds'].valid ||
                    !createCustomPackForm.controls['planIds'].untouched
                  "
                >
                  Plan Name(s) is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[3]" width="100%" height="100%" />
              </div>
            </div>
            <div class="selected-list" *ngIf="!isLoading && selectedSubscriptionList.length > 0">
              Your Selected Plans :
              <ul>
                <li *ngFor="let subscriptionDetail of selectedSubscriptionList; let i = index">
                  {{
                    subscriptionDetail.SubscriptionPlanID +
                      ' (' +
                      subscriptionDetail.SubscriptionPlanName +
                      ' - ' +
                      subscriptionDetail.SubscriptionPlanDurationName +
                      ' | Plan Category - ' +
                      subscriptionDetail.SubscriptionPlanCategory +
                      ')'
                  }}
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Select Plan Name(s)</mat-label>
                <mat-select
                  formControlName="planIds"
                  [(ngModel)]="selectedValue"
                  name="planIds"
                  multiple
                  (selectionChange)="selectItem($event)"
                >
                  <div class="search-input">
                    <input
                      [(ngModel)]="searchTxt"
                      matInput
                      placeholder="Search Plan Name(s)"
                      [ngModelOptions]="{ standalone: true }"
                      class="planIds-name"
                    />
                  </div>
                  <mat-option
                    *ngFor="let subscriptionDetail of subscriptionList | search: searchTxt"
                    [value]="subscriptionDetail.SubscriptionPlanID"
                  >
                    {{
                      subscriptionDetail.SubscriptionPlanID +
                        ' (' +
                        subscriptionDetail.SubscriptionPlanName +
                        ' - ' +
                        subscriptionDetail.SubscriptionPlanDurationName +
                        ' | Plan Category - ' +
                        subscriptionDetail.SubscriptionPlanCategory +
                        ' | Group No - ' +
                        subscriptionDetail.SubscriptionGroupNo +
                        ')'
                    }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    !createCustomPackForm.controls['planIds'].valid ||
                    !createCustomPackForm.controls['planIds'].untouched
                  "
                >
                  Plan Name(s) is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[3]" width="100%" height="100%" />
              </div>
            </div>
            <div class="selected-list" *ngIf="!isLoading && selectedSubscriptionList.length > 0">
              Your Selected Plans :
              <ul>
                <li *ngFor="let subscriptionDetail of selectedSubscriptionList; let i = index">
                  {{
                    subscriptionDetail.SubscriptionPlanID +
                      ' (' +
                      subscriptionDetail.SubscriptionPlanName +
                      ' - ' +
                      subscriptionDetail.SubscriptionPlanDurationName +
                      ' | Plan Category - ' +
                      subscriptionDetail.SubscriptionPlanCategory +
                      ' | Group No - ' +
                      subscriptionDetail.SubscriptionGroupNo +
                      ')'
                  }}
                </li>
              </ul>
            </div>
          </div> -->
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Enter Button Text</mat-label>
                <input matInput placeholder="Enter Button Text" formControlName="buttonText" required />
                <mat-error
                  *ngIf="
                    !createCustomPackForm.controls['buttonText'].valid ||
                    !createCustomPackForm.controls['buttonText'].untouched
                  "
                >
                  Button Text is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[4]" width="100%" height="100%" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Enter Display Order</mat-label>
                <input matInput placeholder="Enter Display Order" formControlName="displayOrder" required />
                <mat-error
                  *ngIf="
                    !createCustomPackForm.controls['displayOrder'].valid ||
                    !createCustomPackForm.controls['displayOrder'].untouched
                  "
                >
                  Display Order is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <!-- <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[3]" width="100%" height="100%" />
              </div> -->
            </div>
          </div>
          <div class="row button-align">
            <button class="btn btn-primary w-100" type="submit" [disabled]="isLoading">
              <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
              <span translate>Create Custom Pack</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
